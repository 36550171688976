<template>
  <div class="inspectionSheet">
    <!-- 相关数据列表 -->
    <Scroll class="region-scroll" ref="scroll">
      <div class="dataModularouter">
        <div
          class="dataModularinner"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="datafirst">
            <div class="firsttext">{{ item.reportname }}：</div>
            <div class="actualValue">
              <div
                class="changecss1"
                v-show="judgeRange(item.result, item.range) == 'h'"
              >
                {{ item.result }}{{ item.unit }}
                <svg class="icon uparrow" aria-hidden="true">
                  <use xlink:href="#icon-arrdown"></use>
                </svg>
              </div>
              <div
                class="changecss3"
                v-show="judgeRange(item.result, item.range) == 'normal'"
              >
                {{ item.result }}{{ item.unit }}
              </div>
              <div
                class="changecss2"
                v-show="judgeRange(item.result, item.range) == 'l'"
              >
                {{ item.result }}{{ item.unit }}
                <svg class="icon downarrow" aria-hidden="true">
                  <use xlink:href="#icon-down1"></use>
                </svg>
              </div>
              <div v-show="judgeRange(item.result, item.range) == 'ohter'">
                {{ item.result }}{{ item.unit }}
              </div>
            </div>
          </div>
          <div class="datasecond">
            <div class="secondtext">参考范围：</div>
            <div class="referenceRange">
              <div
                v-for="(item, index) in rangeHandle(item.range)"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import { testResults } from '@/network/service.js'
import { mapState } from 'vuex'
export default {
  name: 'inspectionSheet',
  data() {
    return {
      list: [],
    }
  },
  components: {
    Scroll,
  },
  computed: {
    ...mapState(['seq', 'cardList']),
  },
  mounted() {
    this.testResultsPost()
    this.judge2()
  },
  methods: {
    async testResultsPost() {
      let res = await testResults({
        seq: this.seq,
        med_id: this.$store.state.med_id,
        sample_no: this.$route.params.item.sampleno,
        name: this.$store.state.cardList[0].name,
      })
      if (res.code == '0') {
        this.list = res.data.root.body.detail
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      } else {
        this.$toast(res.msg)
      }
    },
    rangeHandle(range) {
      return range
        .replace('&lt;', '<')
        .replace('&gt;', '>')
        .split('/n')
    },
    judgeRange(str1, str2) {
      if (Number(str1) < Number(str2.split('-')[0])) {
        return 'l'
      } else if (Number(str1) > Number(str2.split('-')[1])) {
        return 'h'
      } else if (
        Number(str1) <= Number(str2.split('-')[1]) &&
        Number(str1) >= Number(str2.split('-')[0])
      ) {
        return 'normal'
      } else {
        return 'ohter'
      }
    },
    judge2(jy_result) {
      let reg = /^\d[.\d]*\d$/
      if (reg.test(jy_result)) {
        return true
      } else {
        return false
      }
    },
    // }
  },
}
</script>

<style lang="less" scoped>
.inspectionSheet {
  padding-bottom: 1rem;
  background-color: #f5f5f5f5;
  height: 100vh;
  .region-scroll {
    height: 100%;
    overflow: hidden;
    .dataModularouter {
      margin: 1.5rem 0.2rem 0rem;
      width: 100vw;
      .dataModularinner {
        background-color: #ffffff;
        min-height: 5rem;
        width: 100%;
        box-sizing: border-box;
        padding: 0.2rem 0.5rem;
        margin: 0 0 0.2rem 0;
        .datafirst {
          display: flex;
          .firsttext {
            height: 3rem;
            line-height: 3rem;
            flex: 0.6;
          }
          .actualValue {
            height: 3rem;
            line-height: 3rem;
            display: flex;
            flex: 0.4;
            justify-content: flex-end;
          }
        }
        .datasecond {
          display: flex;
          .secondtext {
            // height: 3rem;
            // line-height: 3rem;
            flex: 0.5;
          }
          .referenceRange {
            flex: 0.5;
            height: fit-content;
            text-align: right;
          }
        }
      }
    }
  }
}
.changecss1 {
  color: #ff0000;
}
.changecss2 {
  color: #0000ff;
}
.changecss3 {
  color: #0de20d;
}
.downarrow,
.uparrow {
  width: 1rem;
  height: 1.3rem;
}
</style>
